/**
 * @generated SignedSource<<df7045e95bd4387b07f929fcf4b8f4db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WelcomeMessageReferenceMfeQuery$variables = {
  companyId: string;
};
export type WelcomeMessageReferenceMfeQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WelcomeMessageReferenceMfeFragment_fragment">;
};
export type WelcomeMessageReferenceMfeQuery = {
  response: WelcomeMessageReferenceMfeQuery$data;
  variables: WelcomeMessageReferenceMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WelcomeMessageReferenceMfeQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "WelcomeMessageReferenceMfeFragment_fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WelcomeMessageReferenceMfeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "daccfe54eb19b76fbf529f677dbf1be9",
    "id": null,
    "metadata": {},
    "name": "WelcomeMessageReferenceMfeQuery",
    "operationKind": "query",
    "text": "query WelcomeMessageReferenceMfeQuery(\n  $companyId: ID!\n) {\n  ...WelcomeMessageReferenceMfeFragment_fragment\n}\n\nfragment WelcomeMessageReferenceMfeFragment_fragment on Query {\n  viewer {\n    account {\n      internalId\n      firstName\n      id\n    }\n  }\n  company: node(id: $companyId) {\n    __typename\n    id\n    ... on Company {\n      internalId\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a2821489cfb990b36309c2750dec984";

export default node;
